.search-wrapper {
  display: grid;
  padding-right: 70px;
  /* overflow-x: scroll; */
  /* margin-right: 100px; */
}

.search-input {
  width: 100%;
  /* width: 100%; */
  display: flex;
  justifyContent: center;
  alignItems: center;
  /* margin-left: 10px; */
  flex-direction: row;
}

.search-input input {
  padding: 0 5px;
  border: 2px solid #333745;
  font-family: 'JetBrains Mono', monospace;

}

.loading-for-search{
  position: absolute;
  /* margin-left: 10%; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.searchTopBarWrapper {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 10px 0;

}
.searchInputWrapper {
  height: 70px;
  background-color: #C9BCA6;
  display: flex;
  padding: 15px;
  border: 2px solid #333745;
  flex-direction: column;
  margin-top: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  /* margin: 30px 0; */

}

.searchInputWrapper h2 {
  color: white;
  font-weight: normal;
  text-transform: uppercase;
  margin: 0;

}

.innerSIWrapper {
  display: flex;
  /* padding: 0 10px; */
  justify-content: center;
  align-items: center;
  justify-content: flex-start;
  /* margin-bottom: 10px; */
  width: 100%;
}


.innerSIWrapper button {
 margin-left: 10px;
 background-color: #226581;
 font-family: 'Overpass-Mono', monospace;
 border: 2px solid #333745;
 text-transform: uppercase;
 border-radius: 0;
 padding: 5px 10px;
}

.resultsDiv {
  background-color: #C9BCA6;
  border: 2px solid #333745;
  margin: 10px;
  min-height: 70vh;
}

.resultsDiv h2 {
  color: white;
  text-transform: uppercase;
  /* padding-top: 20px; */
}

.searchBox {
  width: 95%;
  margin: 10px;
  /* margin-left: 10px; */

}

@media (max-width: 1024px){
  .searchBox {
    width: 95%;
  }
}
