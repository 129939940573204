.main-div-wrapper {
  margin: 5px;
  width: 99%;
  border: 2px solid #333745;
  background-color: #5B7553;
}

.main-div-wrapper .maintop {
  background-color: #7F9C76;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.main-div-wrapper .maintop h1 {
  margin: 0;
  padding: 0;
  font-size: 20px;
  color: white;
  font-weight: normal;
  padding: 10px 20px;
  font-family: 'Overpass-Mono', monospace;
  text-transform: uppercase;

}

.pickerWrapper {
  display: flex;
  width: 50%;
  margin: 150px auto;
  flex-direction: column;

}

.pickerWrapper h2 {
  text-transform: uppercase;
  color: white;
  font-family: 'Overpass-Mono', monospace;
  font-weight: 500;
}

.pickerWrapper select {
  width: 80%;
  margin-bottom: 10px;
  font-family: 'JetBrains Mono', monospace;
  padding: 5px;
  border-radius: 0;
  border: 2px solid #333745;
  /* color: #333745; */
}

.pickerWrapper button {
  width: fit-content;
  font-family: 'Overpass-Mono', monospace;
  padding: 6px 12px;
  text-transform: uppercase;
  background: #C9BCA6;
  border: 2px solid #333745;
  border-radius: 0;

}

.tabbed-sections {
  /* display: flex; */
  width: 20%;
  /* justify-content: flex-start;
  flex-direction: row;
  align-items: center; */
  height: 100%;
}

.tabbed-section-item {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  align-content: center;
  height: 100%;
  margin-top: 30px;
}

.innerItem {
  background-color: #C9BCA6;
  border-radius: 0;
  border: 2px solid #333745;
  padding: 12px 24px;
  margin: 10px;
  text-transform: uppercase;
  width: 178px;
  text-align: center;
}

/* .tabbed-section-item .innerItem:hover {
  color: white;
  background-color: #226581;
} */

.innerItem a:hover {
  color: #226581;
}

.fieldsWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.build-fields{
  width: 80%;
  margin: 20px;
  /* padding: 20px; */
  background-color: #7F9C76;
  padding-top: 10px;


}

.itemActive {
  background-color: #226581;
  color: white;
}
.itemActive a {
  color: white;
}

.itemActive a:hover {
  color: #C9BCA6;
}

.bf-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
}

.bf-leftside {
  width: 40%;
}

.bf-rightside {
  width: 60%;
}

.parts-add-button {
  /* GUNMETAL: #333745
  BEIGE: #C9BCA6 */
  background-color: #C9BCA6;
  border: 2px solid #333745;
  border-radius: 0;
  padding: 5px 7px;
  text-transform: uppercase;
  display: inline-block;
  float: none;
  color: white;
  font-family: 'Overpass-Mono', monospace;
}

.parts-table-button-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.parts-add-button:hover {
  background-color: #226581;
}

.parts-table-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.parts-title {
  margin-left: -2px;
}

.parts-table-inner-button {
  text-decoration: underline;
  padding-left: 20px;
}

.parts-table-inner-button a {
  padding-left: 20px;
  padding-right: 20px;
  font-size: 14px;

}

@media (max-width: 1024px){
  .fieldsWrapper {
    padding-left: 20px;
    justify-content: space-around;
  }
  .build-fields {
    width: 72%;
    margin-left: 30px;
  }

  .bf-wrapper {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .bf-leftside {
    width: 100%;
  }

  .bf-rightside {
    width: 100%;
  }
}

.template-fields-wrapper {
  padding: 10px 20px;
}

.template-input-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid lightgrey;
  padding: 10px 0;

}

.dropdown-field select{
  padding: 0 5px;
  border: 2px solid #333745;
  font-family: 'JetBrains Mono', monospace;
  text-align: left;
}

.edit-button {
  background-color: #C9BCA6;
  border: 2px solid #333745;
  border-radius: 0;
  padding: 5px 7px;
  text-transform: uppercase;
  color: white;
  font-family: 'Overpass-Mono', monospace;
}

.edit-button i {
  padding: 0 5px;
}

.edit-button:hover {
  background-color: #226581;
}

.add-q {
  font-size: 15px;
}

.add-q i {
  padding-right: 0;
}

.export-section {
  min-height: 300px;
  padding-left: 30px;
}

.export-section h2 {
  color: white;
  text-transform: uppercase;
  font-weight: normal;
}

.export-section button {
  background-color: #C9BCA6;
  border: 2px solid #333745;
  border-radius: 0;
  padding: 10px 15px;
  text-transform: uppercase;
  color: white;
  font-family: 'Overpass-Mono', monospace;
  font-size: 18px;
  float: left;
}

.export-section button:hover {
  background-color: #226581;
}

.afrl_location_container{
  display: grid;
  gap: 10px;
  grid-template-columns:50px 50px 50px; 
}

.afrl_location_selector{
  width: 50px;
  height: 50px; 
  text-align: center; 
  line-height: 50px; 
  border:1px solid black;
  background-color: antiquewhite;
  cursor: pointer;
}

.afrl_selected_location{
  background-color: #7F9C76;
  color: white;
}
