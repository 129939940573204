.inner-card-wrapper {
  display: block;
}
.found-wrapper {
  display: block;
  overflow: hidden;
}
.context-wrapper {
  margin-left: 15px;
  display: block;
}
