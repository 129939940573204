
.jumbo {
	display: flex;
	justify-content: center;
	align-items: stretch;
	width: 70%;
	/* height: 600px; */
  margin: 300px auto;
}

.login-text {
  width: 50%;
  background-color: #5B7553;
  padding: 20px 20px 50px 20px;
  border: 2px solid #333745;
  min-height: 50%;
}

.login-text h2 {
  text-align: center;
  color: white;
  /* text-transform: uppercase; */
  font-size: 2em;

}

.login-text h4, .login-text a {
  text-transform: uppercase;
  text-align: center;
  color: white;
}

.login-text h4 a {
  text-decoration: underline;
  /* text-align: center; */
  margin: 10px 0;
}


.login-box {
	/* float: right; */
	/* margin-right: 100px; */
	width: 50%;
  /* min-height: 50%; */
  /* margin-left: 20px; */
  /* margin-bottom: 15px; */


}

.login-box-form {
  /* box-shadow: 5px 5px 10px 5px rgba(0,0,0,0.5); */
  border: 2px solid #333745;
  background-color: #C9BCA6;
	/* width: 200px; */
	/* margin-left: 100px; */
  /* padding: 50px 20px 50px 20px; */
  /* margin: 20px 20px 50px 20px; */
  margin-left: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;


}

.login-box-form h2{
  /* text-transform: uppercase; */
  color: white;
  text-align: center;
  font-size: 2em;
}

.login-submit {
	/* margin-top: 10px; */
	/* width: 80%; */
  background-color: #226581;
  text-transform: uppercase;
  font-family: 'JetBrains Mono', monospace;
  margin: 0;
	margin-bottom: 15px;
  border: 2px solid #333745;
  padding: 10px 15px;
  font-size: 1.3em;
}

.login-submit:hover {
  background-color: #5B7553;
  box-shadow: none;
}

@media only screen and (max-width: 1024px) {
	.login-box {
		width: 100%;
		/* margin-left: 4vw; */
		/* margin-right: 4vw; */
	}

	.login-box-form {
		margin: 0;
		padding: 0;
		/* padding-left: 2vw; */
		width: 100%;
	}

	.login-box-form button {
		float: none;
	}

	.login-header {
		padding-top: 1vw;
	}


  .jumbo {
    flex-direction: column;
    margin: 250px auto;
  }

  .login-text {
    margin-bottom: 10px;
  }

}

.home-wrap {
	display: flex;
	flex-direction: column;
}

.home-footer {
	height: 60px;
  background: #343a40;
	color: #FFF;
	display: flex;
	align-items: center;
	justify-content: center;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.login-input{
  padding: 10px 15px;
  margin: 0px 50px;
  margin-bottom: 10px;
  max-width: 300px;
  text-size-adjust: auto;
}

.forgot-password{
  display: inline-block;
}

.input-actions{
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.input-actions button, .input-actions .forgot-password{
  margin: 0px 10px;
  margin-bottom: 10px;
}

.forgot-password:hover{
  color: #888888;
}

.resetmodal {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.resetmodal-card {
  background-color: #fff;
  border-radius: 4px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  width: 90%;
}

.closeresetmodal {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
  font-weight: bold;
  color: #888;
}

.closeresetmodal:hover {
  color: #555;
}

.closeResetModalButton {
  background-color: #226581;
}
