.card {
  border: 2px solid #333745;
	width: 100%;
  background: #C9BCA6;
	font-family: 'Overpass-Mono', monospace;
  /* padding: 0px 20px 15px 20px; */
  margin: 20px 40px 30px 20px;
}

.dashboard-card {
  border: 2px solid #333745;
	width: 100%;
  background: #5B7553;
	font-family: 'Overpass-Mono', monospace;
  /* padding: 0px 20px 15px 20px; */
  /* margin: 12px 40px 12px 5px; */
  margin: 5px;
 }

.card-title {
  color: white;
  margin-left: 15px;
  font-weight: normal;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.dashboard-container .card {
	width: 100%;
}
@media only screen and (max-width: 1024px) {
	.card {
		margin: 0;
		height: 30vh !important;
    margin-bottom: 20px;
	}
}

@media only screen and (max-width: 1000px) {
	.card {
		font-size: 0.8em;
	}
}

.card h3, h4, progress {
  margin: 8px 15px 0px 15px
}

.aptCard {
  background-color: #5B7553
}

.activeProjectsTable {
  width: 100%;
  border-collapse: collapse;
}

.aptHeader {
  padding: 10px 0 10px 10px;
}

.aptHeader td {
  font-size: 20px;
  color: white;
  font-family: 'Overpass-Mono', monospace;
  padding: 10px;
}

#pro {
  padding-left: 20px;
}

#nop {
  width: 20%;
  padding-left: 20px;
}

.aptProjects td {
  padding: 10px 0 10px 20px;

}

.aptProjects td, .aptProjects td a {
  font-family: 'JetBrains Mono', monospace;
  white-space: hidden;
  text-overflow: ellipsis;
  overflow: hidden;

}

.aptProjects:nth-child(even) {
  background-color: #3D4F37
}
.aptProjects:nth-child(even) td, .aptProjects:nth-child(even) td a {
  color: white;
}
.aptProjects:nth-child(odd) {
  background-color: #7F9C76
}

.aptProjects td a:hover {
  color: #C9BCA6;
}

.statswrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 10px;
}


.statswrapper h3 {
  margin: 0;
  padding: 0;
}

.statswrapper .stat {
  font-size: 50px;
}
