.newModal {
  margin-left: 20px;
  margin-right: 20px;
  width: 500px;
}

.newModal h3 {
  color: white;
  text-transform: uppercase;
}

.newModal label {
  color: white;
  text-transform: uppercase;
}

.newModal input {
  border: 2px solid #333745;
  border-radius: 0;
}



.newModal .submitDocButton, .newModal .uploadFileButton, .deleteModal button {
  background-color: #226581;
  border: 2px solid #333745;
  padding: 8px 12px;
  text-transform: uppercase;
  font-family: 'Overpass-Mono', monospace;
  width: fit-content;
  color: white;
  text-transform: uppercase;
  border-radius: 0;
  font-size: 16px;
}

.file-and-button {
  display: flex;
  flex-direction: column;
}

.fileName {
  width: 200px;
  padding-left: 10px;
  margin-bottom: 10px;
  font-family: 'Overpass-Mono', monospace;
  color: #000;
}

.deleteModal p{
  color: white;
  text-transform: uppercase;
  font-weight: bold;
}

.parts-add-modal {
  max-height: 800px;
  width: 700px;
  /* overflow: auto; */
}

.parts-add-modal button {
  /* GUNMETAL: #333745
  BEIGE: #C9BCA6 */
  background-color: #226581;
  border: 2px solid #333745;
  border-radius: 0;
  padding: 5px 7px;
  text-transform: uppercase;
  color: white;
  font-family: 'Overpass-Mono', monospace;
}

.parts-add-modal button:hover {
  background-color: #19546C;
}

.browse-modal {
  width: 100%;
}

.browse-modal h2, .browse-modal h4 {
  width: 80%;
  color: white;
  font-weight: normal;
}

.browse-modal h2 {
  margin-left: 15px;
}

.browse-modal h2 span, .browse-modal h4 span {
  color: #226581;
  text-transform: uppercase;
  font-weight: bold;
}

.browse-modal-wrapper {
  width: 80%;
  max-height: 60%;
  overflow-y: scroll;

}
