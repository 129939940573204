

.top-header-wrapper {
  width: 100%;
  background-color: #e5e5e5;
  height: auto;
}

.top-header {
  background: #226581;
  display: flex;
  height: 70px;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 5px; */
  z-index: 20;
  position: fixed;
  width: 95%;
  padding: 0 40px;
  border: 2px solid #333745;
  margin: 5px;
}

.top-header h1{
  color: white;
  font-family: 'Overpass-Mono', monospace;
  width: auto;
  font-weight: bold;
  font-size: 40px;
  line-height: 61px;
  letter-spacing: 0.05em;
  /* text-transform: uppercase; */
}

.navigationWrapper {
  display: flex;
  justify-content: space-evenly;
  width: 60%;
  /* padding: 0 15px; */
  align-items: center;

}

.navigationItem a{
  font-family: 'Overpass-Mono', monospace;
  font-weight: normal;
  font-size: 20px;
  line-height: 31px;
  text-transform: uppercase;
  color: white;
  margin: 0 20px;
}


.navigationItem a:hover, .navigationItem a:active{
  font-family: 'Overpass-Mono', monospace;
  font-weight: normal;
  color: #C9BCA6;
}

.activeNavItem {
  color: #C9BCA6;
}

.activeNavItem:hover {
  color: white;
}

.subNavWrapper {
  width: fit-content;
  height: 100px;
  border: 2px solid #333745;
  background-color: #C9BCA6;
  margin: 10px 10px 0 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;


}

.subNav {
  display: flex;
  justify-content: flex-start;
  width: fit-content;
  /* padding: 0 15px; */
  align-items: center;
  margin: 15px;
  padding: 20px;

}

.subNavItem {
  width: 'fit-content';
  text-align: center;
  margin: 0 30px;
}

.subNavItem a{
  font-family: 'Overpass-Mono', monospace;
  font-weight: normal;
  font-size: 16px;
  line-height: 31px;
  text-transform: uppercase;
  color: white;
  margin: 0 auto;
  text-align: center;
  line-height: 16px;
}

.subNavItem a:hover, .subNavItem a:active{
  font-family: 'Overpass-Mono', monospace;
  font-weight: normal;
  color: #226581;
}

.activeSubItem a {
  color: #226581;
  font-weight: bold;
}

.activeSubItem a:hover, .activeSubItem a:active{
  color: white;
  font-weight: normal;
}

.bottom-nav-footer {
  height: 60px;
  display: flex;
  width: 100%;
  background-color: #3D4F37;
  justify-content: flex-end;
}

.bottomnav {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottomnav button, .bottomnav a, .backbutton{
  background-color: #C9BCA6;
  border: 2px solid #333745;
  font-family: 'Overpass-Mono', monospace;
  text-transform: uppercase;
  color: #333745;
  margin: 10px;
  padding: 8px 20px;
  font-size: 15px;
}

.bottomnav button:hover, .bottomnav a:hover, .backbutton:hover {
  background-color: #226581;
  color: white;
}

.viz-page {
  margin: 20px;
}

.vizpagewrapper, .browsewrapper {
  margin: 20px 10px;
}

.action-wrapper {
  background-color: #5B7553;
  padding: 15px;
  border: 2px solid #333745;
  height: 70px;
  width: 30%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
}

.new-button {
  background-color: #C9BCA6;
  border: 2px solid #333745;
  border-radius: 0;
  font-size: 16px;
  padding: 10px;
  font-family: 'Overpass-Mono', monospace;
  text-transform: uppercase;
}

.new-button:hover {
  background-color: #226581;
}

.noDocRows {
  background-color: #226581;
  border: 2px solid #333745;
  padding: 20px 40px;
  width: 60%;
  margin: 0 auto;
  margin-top: 100px;
}

.noDocRows h2 {
  text-align: center;
  text-transform: uppercase;
  color: white;
}

.noDocRows button:hover {
  background-color: #5B7553;
}


@media (max-width: 1026px){
  .navigationItem a {
    font-size: 16px;
  }

  .subNavItem {
    margin: 0 20px;
  }

  .subNavItem a {
    font-size: 14px;
  }

  .new-button {
    font-size: 12px;
  }
}
