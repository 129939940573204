/**
 *
 *    GENERAL CSS TO FIX:
 *     - There's an !important war amongst the card class members
 *
 */

 /***
 new trace design:

 fonts:
 Overpass-Mono,
 JetBrains Mono

 colors:
 BACKGROUND: #E5E5E5
 MED GRAY: #808080
 FADED BLACK: #2D2D2D
 GUNMETAL: #333745
 BEIGE: #C9BCA6
 SAPPHIRE: #226581
 MUTED SAPPHIRE: #498AA5
 DARKER SAPPHIRE: #19546C
 FERN: #5B7553
 MUTED FERN: #7F9C76
 DARKER FERN: #3D4F37


 **/

@import "./assets/css/fonts.css";

body {
  font-family: 'Overpass-Mono', monospace;
  background-color: #E5E5E5;
  margin: 0;
  padding: 0;
}

.LoggedInContainer {
  display: flex;
  flex-direction: column;
}

.cursor {
        cursor: pointer;
}

.menu-item i {
	margin-right: 8px;
}

a {
	color: black;
	text-decoration: none;
}

a:hover {
  color: #C9BCA6;
}

a:-webkit-any-link {
	text-decoration: none;
}

.center-flex {
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
}




.bottom-nav {
	margin-top: 5vh;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20vw;
	background: #FFF;
	border: 1px solid rgba(0,0,0,0.125);
	border-radius: 10px;
}

.save-button {
	border-right: 1px solid rgba(0,0,0,0.125);
	border-left: 1px solid rgba(0,0,0,0.125);
}

.save-button:hover {
  color: rgb(0, 154, 212);
}

/*
 //////////////////////////////////////////////////////////////////////////////
 progress bar
 TODO: make this responsive
 */

.progress-bar {
	display: flex;
	padding-left: 2vh;
	align-items: center;
  justify-content: flex-start;
	background: transparent;
  margin-top: 10px;
	z-index: 0;
}

.progress-bar-items {
	display: flex;
  font-size: .75em;
	align-items: center;
/*	margin-left: 2vw; */
	border: 1px solid rgba(0,0,0,0.125);
	border-radius: 10px;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.1);
}

div.progress-bar-items div {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 130px;
  height: 40px;
  text-align: center;
	background-color: #FFF;
  padding: 5px;
	border-right: 1px solid #CED4DA;
}
.progress-bar-items div:first-child  {
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}

.progress-bar-items div:last-child {
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
}


.progress-bar-items .active {
	/* TODO - make this a better style */
	background-color: rgb(111, 207, 62);
	font-weight: bold;
}
.progress-bar-items .active a{
	/* TODO - make this a better style */
	color: white;
}

/*///////////////////////////////////////////////////////////////////////////*/


/*////////////////////// task list //////////////////////////////////////////*/

.inone {
	display: none;
}

.ipast {
	display: flex;
	border: 1px solid black;
	position: relative;
	top: 360px;
}
.ipast button {
	display: none
}

.icurrent {
	position: fixed;
	top: 80px;
	margin-top: 20px;
	background: #FFF;
	padding: 20px;
	height: 240px;
	width: 80%;
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
}

	.icurrent button {
		margin-top: 50px;
	}




.topbar {
  background: rgb(111, 207, 62);
  position: fixed;
  display: flex;
  height: 70px;
	width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px 0px 0px;
  z-index: 50;
  box-shadow: 5px 0px 10px rgba(0,0,0,0.5);
}


.main-text {
	padding-left: 200px;
	padding-right: 200px;
  	margin-top:50px;
}

@media only screen and (max-width: 1024px) {
	.main-text {
		padding: 0;
		padding-left: 4vw;
		margin-top:0;
	}
}

.home-header {
  background: #343a40;
  position: fixed;
	margin-bottom: 86px;
	top: 0;
  display: flex;
  height: 86px;
	width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px 0px 0px;
  z-index: 50;
  box-shadow: 5px 0px 10px rgba(0,0,0,0.5);
}

.topbar-logo {
  line-height: 65px;
  text-align: center;
  width: 240px;
  height: 70px;
  padding-bottom: 20px;
  flex-shrink: 0;
  color: #363636;
}

.login-tile {
  width: 35px;
  height: 35px;
  margin-right: 30px;
}

#login-svg {
  color: #FFF;
  font-size: 40px;
}

.main-page-wrapper {
  display: flex;
  width: 100vw;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  min-height: calc(100vh - 70px);
  margin-top: 70px;

}

.maindiv {
  /* margin-left: 20px; */
  width: 100%;
  /* background: #EEF5F9; */
  background: #E5E5E5;
  /* padding: 70px 30px 25px 30px; */
  /* margin-right: auto; */
  min-height: calc(100vh - 70px);
  height: auto;
  overflow-y: scroll;
  /* height: 100%; */
  /* padding-left: 20px; */
  /* padding-right: 20px; */
  /* margin-left: 15%; */
  /* padding-left: 20px; */
  /* padding-top: 5px; */
  /* padd */

}

.sidebardiv{
  background: #FFF;
	/* position: fixed; */
  width: 15%;
  top: 0px;
  z-index: 0;
  /* padding-top:70px; */
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
  height: calc(100vh - 70px);
  /* height: auto; */
}

/* @media (max-width: 1500px) {
  .sidebardiv {
    width: 15%;
  }
} */

.sidebar-ul{
  background: #FFF;
  position: fixed;
}

.main-header {
	position: relative;
	z-index: 1;
  background: #FFF;
  margin: 0px -30px 30px;
  padding: 15px;
  box-shadow: 1px 0px 5px rgba(0,0,0,0.1);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-family: 'Overpass-Mono', sans-serif;
}

.main-header button {
	margin: 0;
	border: 1px solid rgb(111, 207, 62);
	height: 50%;
	min-width: 50px;
	border-radius: 6px;
}

.main-header button:hover {
	box-shadow: 0 4px 4px 0 rgba(111, 207, 62, 0.14), 0 3px 1px -2px rgba(111, 207, 62, 0.2), 0 1px 5px 0 rgba(111, 207, 62, 0.12);
	transition: 0.2s ease-in;
}

.white-text {
	color: #FFF;
}

.white {
	background: #FFF;
	color: rgb(111, 207, 62);
}

.blue {
	color: #FFF;
	background: rgb(111, 207, 62);
}

.breadcrumb {
  padding: 0px;
  background: transparent;
  font-size: 14px;
  margin-bottom: 0px;
  display: flex;
	align-items: center;
  flex-wrap: wrap;
  list-style: none;
  border-radius: .25rem;
  margin-top: 0px;
  font-family: 'Overpass-Mono', sans-serif;
  color: #000000;
}

.breadcrumb h3 {
  margin: 0;
}

.breadcrumb-item {
  margin-right: 10px;
	margin-bottom: 0;
  color: #000000;
  cursor: pointer;
}

.breadcrumb-last {
  color: #000;
}

h3 {
  margin: 8px 0px 0px 0px;
  font-size: 21px;
  color: rgb(0, 154, 212);
}

.right-tiles {
  display: flex;
  margin-right: 40px;
  justify-content: space-between;
  width: 300px;
}

.right-tiles p {
  margin:0;
}

.right-tiles h2 {
  margin: 0;
  color: rgb(111, 207, 62);
}

.cols {
  display: flex;
  flex-direction: column;
  align-items: center;
	margin-right: 10px;
	text-align: center;
}



.row-spread {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px;
}

.row {
  display: block;
}

.row span {
  margin-left: 20px;
  margin-top: 9px;
  color: white;
}

/*
.row h2 {
  margin-left: 70px;
  margin-top: 5px;
}
*/



.analytics-container {
	display: flex;
	flex-direction: column;
  margin: 20px 20px 0 0;
}

.lcard {
  border: 1px solid rgba(0,0,0,0.125);
  border-radius: 4px;
  /* box-shadow: 0px 5px 20px rgba(0,0,0,0.05); */
  width: 400px;
  /* height: 100px; */
  background: #FFF;
  font-family: 'Overpass-Mono', sans-serif;
  margin-bottom: 1em;
	margin-left: 30px;

}

.lcard i {
  margin-left: 15px;
  margin-top: 10px;
}

@media only screen and (max-width: 1024px) {
	.dashboard-container .card {
		height: 100% !important;
	}

	.analytics-container {
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-top: 2vh;
		width: 95%;
	}

	.lcard {
		width: 30vw;
		padding: 1vw;
		margin-left: 0;
	}
}

@media only screen and (max-width: 900px) {
	.analytics-container {
		justify-content: center;
	}
	.lcard {
		width: 80%
	}
}


h5 {
	margin: 0;
}

.page-form {
	min-height: 850px;
	width: 90%;
	background: #FFF;
	height: 800px;
	width: 100%;
	margin-top: -15px;
	border-color: #D7DFE3;
	border-radius: 4px;
	box-shadow: 0px 5px 20px rgba(0,0,0,0.05);
	border: 1px solid rgba(0,0,0,0.125);
}
.page-form200 {
	width: 90%;
	background: #FFF;
	width: 100%;
	margin-top: -15px;
	border-color: #D7DFE3;
	border-radius: 4px;
	box-shadow: 0px 5px 20px rgba(0,0,0,0.05);
	border: 1px solid rgba(0,0,0,0.125);
	padding-bottom: 40px
}

.page-form2 {
	width: 90%;
	background: #FFF;
	height: 600px;
	width: 100%;
	margin-top: -15px;
	border-color: #D7DFE3;
	border-radius: 4px;
	box-shadow: 0px 5px 20px rgba(0,0,0,0.05);
	border: 1px solid rgba(0,0,0,0.125);
}

.form-header {
  background:	rgb(0, 154, 212);
	color: #FFF;
	border-radius: 4px 4px 0px 0px;
	box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.125);
}

.form-header.fade {
	opacity: 1;
}

.form-header.fade.inactive:hover{
	opacity: 0.5;
}

.highlight .form-header:hover {
  background:	rgba(0, 154, 212, 0.75);
	cursor: pointer;
}

.form-header h3 {
  font-family: 'Overpass-Mono', sans-serif;
	margin-top: 0px;
	color: #FFF;
	padding: 4px 0px 10px 0px;
	margin-left: 20px;
}

.form-row {
	/* border-bottom: 1px solid rgba(120, 130, 140, 0.13); */
	/* margin-bottom: 8px; */
	display: flex;
  flex-direction: column;
  font-family: 'Overpass-Mono', sans-serif;
	align-items: flex-start;
  justify-content: flex-start;
  margin: 10px 20px;
  width: 400px;
}

.form-field {
  margin: 5px 0;
  width: 400px;
  /* padding: 5px; */
}

.form-field input, .form-field textarea, .form-field select {
  padding: 5px;
  border: 2px solid #333745;
  font-family: 'JetBrains Mono', monospace;
}

.form-label {
  margin: 5px 0;
  font-family: 'Overpass-Mono', monospace;
  text-transform: uppercase;
  color: white;
}

input {
	color: #67757C;
	height: 32px;
	display: initial;
	width: 100%;
	padding: .375rem, .75rem;
	background-color: #FFF;
	border: 1px solid #CED4DA;
	border-radius: .25rem;
	transition: border-color, box-shadow;
  transition-duration: 0.15s, 0.15s;
  transition-timing-function: ease-in-out, ease-in-out;
	transition-delay: initial, initial;
	overflow: visible;
}

textarea {
	color: #67757C;
	height: 96px;
	display: initial;
	width: 100%;
	padding: .375rem, .75rem;
	background-color: #FFF;
	border: 1px solid #CED4DA;
	border-radius: .25rem;
	transition: border-color, box-shadow;
  transition-duration: 0.15s, 0.15s;
  transition-timing-function: ease-in-out, ease-in-out;
	transition-delay: initial, initial;
	overflow: visible;

}

select {
	color: #67757C;
	height: 32px;
	display: initial;
	width: 100%;
	padding: .375rem, .75rem;
	background-color: #FFF;
	border: 1px solid #CED4DA;
	border-radius: .25rem;
	transition: border-color, box-shadow;
  transition-duration: 0.15s, 0.15s;
  transition-timing-function: ease-in-out, ease-in-out;
	transition-delay: initial, initial;
	overflow: visible;
}

button:hover {
	box-shadow: 0 2px 2px 0 rgba(66, 165, 245, 0.14), 0 3px 1px -2px rgba(66, 165, 245, 0.2), 0 1px 5px 0 rgba(66, 165, 245, 0.12);
	transition: 0.2s ease-in;
	background: rgb(0, 135, 245);
  cursor: pointer;
}

button {
	color: white;
	border: 2px solid rgb(0, 135, 245);
	background: rgba(66, 165, 245, 1);
	border: 0px;
	padding: 7px;
	border-radius: 3px;
	float: right;
	margin-right: 20px;
}

li {
	margin-bottom: 10px;
	list-style: none;
}

.menu-item {
	position: relative;
	display: block;
	font-size: 1.2em;
	white-space: nowrap;
	padding: 8px 35px 8px 15px;
	border-radius: 4px;
	cursor: pointer;
	vertical-align: middle;
}

a i{
	margin-right:10px;
}

ul {
	padding-left: 10px;
}

.left {
	padding-left: 50px;
}

.logo-img {
	width: 100%
}

.flexy {
	display: flex;
}

.section {
	border-bottom: 1px solid white;
}

.acc-head {
	border: 1px solid green;
	height:50px;
	width: 100%;
}

.content {
height:0;
overflow: hidden;
transition: all .3s ease-in;
}

.section.open .content {
	height: 100%;
}

.table-basic {
	background: #FFF;
	padding: 4px;
	border-collapse: collapse;
	table-layout: auto;
	width: 100%;
}

.table-basic thead {
	background: rgb(0, 154, 212);
	color: white;
	font-weight: bold;
}

thead td {
	padding: 6px;
	padding-left: 12px;
	padding-right: 12px;
}

.table-basic tbody td {
	border-top: 1px solid #DEE2E6;
	border-bottom: 1px solid #DEE2E6;
}

.hide {
	visibility: hidden;
}


.complete-section {
	  visibility: visible; /* shows sub-menu */
		  opacity: 1;
			  z-index: 1;
	  transform: translateY(0%);
	  transition-delay: 0s, 0s, 0.3s; /* this removes the transition delay so the menu will be visible while the other styles transition */
	  border-bottom: 1px solid white;

}
.complete-section .inactive {
	  visibility: hidden; /* hides sub-menu */
	  opacity: 0;
	  position: absolute;
	  top: 100%;
	  left: 0;
	  width: 100%;
	  transform: translateY(-2em);
	  transition: all 0.6s , visibility 0s linear 0.3s, z-index 0s linear 0.01s;
}
.bottom-nav{
	display: flex;
	justify-content: flex-start;
	height: 30px;
}
.space{
	padding:10px;
	margin-top:0px;
}

.new-doc{

}

.submit-doc{
  cursor: pointer;
  width: 80px;
	color: white;
	border: 2px solid rgb(0, 135, 245);
	background: rgba(66, 165, 245, 1);
	border: 0px;
	padding: 7px;
	border-radius: 3px;
	float: right;
	margin-right: 20px;
}
.submit-doc:hover{
	background: rgb(0, 135, 245);
}
.inputfile {
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}
.upload {
  font-size: .8em;
	color: white;
	border: 2px solid rgb(0, 135, 245);
	background: rgba(66, 165, 245, 1);
	border: 0px;
	padding: 7px;
	border-radius: 3px;
	float: right;
	margin-right: 20px;
}
.upload:hover{
	box-shadow: 0 2px 2px 0 rgba(66, 165, 245, 0.14), 0 3px 1px -2px rgba(66, 165, 245, 0.2), 0 1px 5px 0 rgba(66, 165, 245, 0.12);
	transition: 0.2s ease-in;
	background: rgb(0, 135, 245);
  cursor: pointer;
}

.upload-submit-doc{
	display: flex;
	justify-content: space-between;
}
.docList{
	display: flex;
	justify-content: space-around;
	border:1px solid black;
	height: 25%;
}
.right-border{
	border-right: #000;
}
.aps{
  display:flex;
  justify-content: space-between;
}

.action-link:hover {
  color: rgb(0, 154, 212);
  cursor: pointer;
}

#sidebar-project-header a:hover {
	color: #000;
}

#sidebar-project-header {
	cursor: default !important;
}
