.dashboard-container {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	width: 99%;
	padding: 10px 0;
}

.dashboard-container .left-side {
	display: flex;
	flex-direction: column;
	width: 50%;
	justify-content: flex-start;
	align-items: flex-start;
	margin-right: 10px;
}

.dashboard-container .right-side {
	display: flex;
	flex-direction: column;
	width: 50%;
	justify-content: flex-start;
	align-items: flex-start;
	padding-right: 10px;
	overflow: hidden;
	/* margin-right: 10px; */
}

@media only screen and (max-width: 1024px) {

	.dashboard-container .left-side, .dashboard-container .right-side {
			width: 95%;
			justify-content: center;
	}

	.dashboard-container {
		flex-direction:column;
    margin-top: 20px;
    margin-left: 20px;
	}

	.dashboard-container .card {
		width: 90%;
	}

}

.multidivs {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	flex-direction: row;
}

.sm-card {
	width: 50%;
	padding: 15px;
	background-color: #333745;
}

.sm-card h3 {
	color: white;
	padding-left: 20px;
	font-size: 32px;

}

.bg-card {
	width: 100%;
	background-color: #333745;
	padding-left: 5px;

}

.bg-card .js-plotly-plot {
	margin: 30px;
}
