@import url("https://pro.fontawesome.com/releases/v5.10.1/css/all.css");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");

.pvtContainer{
  overflow:auto;
}

.pvtUi {
  color: #333745;
  font-family: 'Overpass-Mono', monospace;
  border-collapse: collapse;
  overflow: scroll;
}
.pvtUi select {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
}

.pvtUi td.pvtOutput {
  vertical-align: top;
  background-color: #C9BCA6;
  border: 2px solid #333745;
}

table.pvtTable {
  font-size: 8pt;
  text-align: left;
  border-collapse: collapse;
  margin-top: 3px;
  margin-left: 3px;
  font-family: 'Overpass-Mono', monospace;

}
table.pvtTable thead tr th,
table.pvtTable tbody tr th {
  background-color: #ebf0f8;
  border: 1px solid #c8d4e3;
  font-size: 8pt;
  padding: 5px;
}

table.pvtTable .pvtColLabel {
  text-align: center;
}
table.pvtTable .pvtTotalLabel {
  text-align: right;
}

table.pvtTable tbody tr td {
  color: #333745;
  padding: 5px;
  background-color: #fff;
  border: 1px solid #c8d4e3;
  vertical-align: top;
  text-align: right;
}

.pvtTotal,
.pvtGrandTotal {
  font-weight: bold;
}

.pvtRowOrder,
.pvtColOrder {
  cursor: pointer;
  width: 15px;
  margin-left: 5px;
  display: inline-block;
  user-select: none;
  text-decoration: none !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
}

.pvtAxisContainer,
.pvtVals {
  border: 2px solid #333745;
  background: #C9BCA6;
  padding: 5px;
  min-width: 20px;
  min-height: 20px;
}

.pvtRenderers {
  padding-left: 5px;
  user-select: none;
  background-color: #C9BCA6;
  border: 2px solid #333745;
}

.pvtDropdown {
  display: inline-block;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
  margin: 3px;
}

.pvtDropdownIcon {
  float: right;
  color: #a2b1c6;
}
.pvtDropdownCurrent {
  text-align: left;
  border: 1px solid #a2b1c6;
  border-radius: 2px;
  display: inline-block;
  position: relative;
  width: 210px;
  box-sizing: border-box;
  background: white;
}

.pvtDropdownCurrentOpen {
  border-radius: 2px 2px 0 0;
}

.pvtDropdownMenu {
  background: white;
  position: absolute;
  width: 100%;
  margin-top: -1px;
  border-radius: 0 0 2px 2px;
  border: 1px solid #a2b1c6;
  border-top: 1px solid #dfe8f3;
  box-sizing: border-box;
}

.pvtDropdownValue {
  padding: 2px 5px;
  font-size: 12px;
  text-align: left;
}
.pvtDropdownActiveValue {
  background: #ebf0f8;
}

.pvtVals {
  text-align: center;
  white-space: nowrap;
  vertical-align: top;
  padding-bottom: 12px;
}

.pvtRows {
  height: 35px;
}

.pvtAxisContainer li {
  /* padding: 8px 6px; */
  width: fit-content;
  list-style-type: none;
  cursor: move;
}
.pvtAxisContainer li.pvtPlaceholder {
  -webkit-border-radius: 2px;
  padding: 3px 15px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  border: 1px dashed #a2b1c6;
}
.pvtAxisContainer li.pvtPlaceholder span.pvtAttr {
  display: none;
}

.pvtAxisContainer li span.pvtAttr {
  text-align: left;;
  -webkit-text-size-adjust: 100%;
  font-size: 14px;
  display: inline-block;
  background: #226581;
  border: 1px solid #c8d4e3;
  padding: 2px 5px;
  white-space: nowrap;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
}

.pvtAxisContainer li span.pvtAttr::before {
  display: inline-block;
  font-style: normal;
  white-space: nowrap;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "JetBrains Mono", monospace;
  font-weight: 900;
  font-size: 0.75em;
  content: "\f58e";
  vertical-align: middle;
  margin-right: 4px;
  opacity: 20%;
}

.pvtTriangle span {
  cursor: pointer !important;
  color: #226581;
  float: right;
  opacity: 50%;
}

.pvtHorizList{
  text-align: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-x: scroll;
  height: 60vh;
  background-color: #C9BCA6;
  border: 2px solid #333745;
}

.pvtCols {
  height: 10vh;
}

.pvtHorizList li {
  display: inline-block;
  padding-right: 5px;

}

.pvtVertList {
  vertical-align: top;
  text-align: -webkit-center;
}


.pvtFilteredAttribute {
  font-style: italic;
}

.sortable-chosen .pvtFilterBox {
  display: none !important;
}

.pvtCloseX {
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 18px;
  cursor: pointer;
  text-decoration: none !important;
}

.pvtDragHandle {
  position: absolute;
  left: 5px;
  top: 5px;
  font-size: 18px;
  cursor: move;
  color: #a2b1c6;
}

.pvtButton {
  color: #226581;
  font-weight: bold;
  background: transparent;
  padding: 3px 6px;
  font-size: 14px;
  margin: 3px;
  text-decoration: none !important;
}

.pvtButton:hover {
  color: #6683e0;
}

.pvtButton:active {
  box-shadow: inset 0px 0px 5px #c1c1c1;
}

.pvtFilterBox input {
  border: 1px solid #c8d4e3;
  border-radius: 2px;
  color: #506784;
  padding: 0 3px;
  font-size: 14px;
}

.pvtFilterBox input:focus {
  border-color: #119dff;
  outline: none;
}

.pvtFilterBox {
  z-index: 100;
  width: 300px;
  border: 1px solid #506784;
  background-color: #fff;
  position: absolute;
  text-align: center;
  user-select: none;
  min-height: 100px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
}

.pvtFilterBox h4 {
  margin: 15px;
}
.pvtFilterBox p {
  margin: 10px auto;
}
.pvtFilterBox button {
  color: #226581;
}
.pvtFilterBox input[type='text'] {
  width: 230px;
  color: #2a3f5f;
  margin-bottom: 5px;
}

.pvtCheckContainer {
  text-align: left;
  font-size: 14px;
  white-space: nowrap;
  overflow-y: scroll;
  width: 100%;
  max-height: 30vh;
  border-top: 1px solid #333745;
}

.pvtCheckContainer p {
  margin: 0;
  margin-bottom: 1px;
  padding: 3px;
  cursor: default;
}

.pvtCheckContainer p.selected {
  background: #333745;
  /* margin-top: 2px;
  margin-bottom: 2px; */
  margin: 5px;
}

.pvtOnly {
  display: none;
  width: 35px;
  float: left;
  font-size: 12px;
  padding-left: 5px;
  cursor: pointer;
}

.pvtOnlySpacer {
  display: block;
  width: 35px;
  float: left;
}

.pvtCheckContainer p:hover .pvtOnly {
  display: block;
}
.pvtCheckContainer p:hover .pvtOnlySpacer {
  display: none;
}

.pvtRendererArea {

  padding: 5px;
}

.filter-bar {
  width: 100%;
  margin-top: 40px;
  margin-left: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.filter-bar button {
  background-color: #C9BCA6;
  border: 2px solid #333745;
  border-radius: 0;
  padding: 8px 16px;
  text-transform: uppercase;
  color: white;
  font-family: 'Overpass-Mono', monospace;
}


.filter-bar button:hover {
  background-color: #226581;
}
