.myChartCard {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #ccc;
  margin: 10px;
  padding: 10px;
  width: 30%;
  background-color: #5b7553;
  overflow: hidden;
}

.myChartPlot {
  /* border: 1px solid red; */
  width: 90%;
  overflow: hidden;
}

.myChartButtonRow { 
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.myChartActiveButton {
  white-space: nowrap;
}

.myChartInactiveButton {
  pointer-events: none;
  opacity: 0.7;
  background-color: #ddd;
  color: #666;
  cursor: not-allowed;
  white-space: nowrap;
  min-width: 150px;
}

