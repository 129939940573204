
.checkboxGroup {
  padding-left: 10px;
}
.checkboxGroup h4{
  font-family: 'Overpass-Mono', monospace;
  text-transform: uppercase;
  color: white;
  font-weight: normal;
}

.checkbox td label{
  color: white;
  font-family: 'JetBrains Mono', monospace;
}

/* .checkbox td input {
  width: 20;
  margin-left: 60;

} */

.form-row-wrapper {
  margin-left: 30px;
  border-bottom: '1px solid rgba(120, 130, 140, 0.13)';
  margin-bottom: 12px;
}

.parts-title {
  color: white;
  text-transform: uppercase;
  font-weight: normal;
}
