.infoContainer {
    display: flex;
}


.machineCard {
  flex-direction: column;
  border: 1px solid black;
  margin: 10px;
  padding: 10px;
  width: 50%;
}

.plotCard {
  flex-direction: column;
  border: 1px solid black;
  margin: 10px;
  padding: 10px;
  width: 100%;
  background-color: #5b7553;
}

.eventsCard {
  flex-direction: column;
  border: 1px solid black;
  margin: 10px;
  padding: 10px;
  width: 100%;
  overflow: auto;
}

.eventfilterContainer {
    display: flex;
}

.eventsearchBox {
  flex-direction: column;
  margin: 30px;
  padding: 30px;
  width: 30%;
}

.eventfilterBox {
  flex-direction: row;
  margin: 30px;
  padding: 30px;
  display: flex;
  overflow: auto;
}

.renTable {
  width: auto; /* Makes the table full width */
  /* border: 1px solid white; */
  overflow: auto;
}

.renCol1 {
  width: auto; /* Makes the table full width */
  text-align: right; /* Right-aligns the first column */
  padding-right: 30px; /* Adds spacing between the columns */
  border-bottom: 1px solid #5b7553;
}

.renCol2 {
  width: auto; /* Makes the table full width */
  text-align: left; /* Left-aligns the second column */
  border-bottom: 1px solid #5b7553;
}

