.card-wrapper {
  width: 100%;
  height: auto;
  /* display: block; */
  padding: 0;
  display: flex;
  flex-flow: column-reverse wrap;
  align-items: flex-start;
  justify-content: flex-start;
  /* margin: 15px; */
  overflow: hidden;
  background-color: #5B7553;
  /* cursor: pointer; */
}

.card-wrapper h1, .card-wrapper h2, .card-wrapper h3, .card-wrapper h4, .card-wrapper h5, .card-wrapper h6, .card-wrapper a, .card-wrapper p {
  color: white;
}

.title-wrap {
  width: 100%;
  background-color: #7F9C76;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.spec-card-title {
  /* color: rgba(66, 165, 245, 1); */
  color: white;
  font-size: 20px;
  width: 100%;
  margin-left: 10px;
}
.spec-card-icon {
  width: 30%;
  /* float: right; */
  text-align: center;
}
.spec-card-icon i{
  font-size: 25px;
  color: white;
}

.content-wrapper {
  display: flex;
  justify-content: flex-start;
  /* padding: 0 20px; */
  flex-flow: column wrap;
  width: 100%;
  align-items: stretch;
  align-content: flex-start;
  /* padding-right: 25px; */
  /* margin-right: 5px; */
  /* margin-top: 5px; */
  /* padding-top: 5px; */
  margin-bottom: 10px;
}

/* for individual cards */
.p-data {
  font-weight: 100;
  color: white;
}

.contentTitle{
  background-color: #3D4F37;
  display: block;
  padding: 0px 0px 6px 0px;
  width: 100%;
  margin: 0;
}

.contentTitle h3 a{
  font-size: 1em;
  text-decoration: none;
  text-transform: uppercase;
  color: white;
  width: 100%;
}

.contentLabel {
  font-size: 12px;
  text-transform: uppercase;
  color: white;
  padding-left: 15px;
  padding-top: 10px;
  margin-top: 0;
  margin-bottom: 0;
}

.contentTitle h2, .contentLabel h3 {
  margin-top: 0;
  margin-bottom: 0;
}

.left-side-of-title {
  display: flex;
  width: fit-content;
  justify-content: flex-start;
  align-items: center;
  margin: 0 10px;
}

.right-side-of-title {
  width: fit-content;
  margin: 0 10px;
}

.right-side-of-title button {
  background-color: #226581;
  border: 2px solid #333745;
  padding: 8px 12px;
  text-transform: uppercase;
  font-family: 'Overpass-Mono', monospace;
  width: fit-content;
  color: white;
  text-transform: uppercase;
  border-radius: 0;
  font-size: 16px;
}

.right-side-of-title button:hover {
  background-color: #C9BCA6;
}

.inner-content-wrapper h4 {
  margin-top: 0;
}

.inner-content-wrapper p{
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 0;
}
