@import url(https://overpass-30e2.kxcdn.com/overpass-mono.css);
@import url(https://overpass-30e2.kxcdn.com/overpass.css);

@font-face{
  font-family: 'JetBrains Mono';
  src: url('./fonts/JetBrainsMono/JetBrainsMono-Bold.woff2') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face{
  font-family: 'JetBrains Mono';
  src: url('./fonts/JetBrainsMono/JetBrainsMono-BoldItalic.woff2') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face{
  font-family: 'JetBrains Mono';
  src: url('./fonts/JetBrainsMono/JetBrainsMono-Italic.woff2') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face{
  font-family: 'JetBrains Mono';
  src: url('./fonts/JetBrainsMono/JetBrainsMono-Regular.woff2') format('truetype');
  font-weight: normal;
  font-style: normal;
}
